import { Component, Inject, OnInit, SecurityContext } from '@angular/core';
import { DIALOG_DATA } from '@angular/cdk/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { AddPointModelComponent } from '../add-point-model/add-point-model.component';
import { MatDialog } from '@angular/material/dialog';
import { AddAlertModelComponent } from '../add-alert-model/add-alert-model.component';
import { ConfirmationModelComponent } from '../confirmation-model/confirmation-model.component';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from '@/shared/services/api.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { defaultClickViewPoint } from 'src/app/shared/constants/default-click-point';

@Component({
  selector: 'app-equip-config-model',
  templateUrl: './equip-config-model.component.html',
  styleUrls: ['./equip-config-model.component.scss']
})
export class EquipConfigModelComponent implements OnInit {
  configId: string;
  saveButtonDisable: boolean;
  pipeRefs: any;

  constructor(@Inject(DIALOG_DATA) public data,
    public dialog: MatDialog,
    public dom: DomSanitizer,
    public dialogRef: MatDialogRef<EquipConfigModelComponent>,
    public apiService: ApiService,
    public storageService: StorageService) { }

  pointList: any = [];
  alertList: any = [];
  customPointList: any = [];
  pointLimit: number = 13;
  masterCount: number;
  isSensorNode: boolean;
  isLoading:boolean = true;
  equipList: any = {
    parentEquip: {},
    childEquip: []
  };
  disableRearrange:boolean = false;
  isReordered:boolean = false;
  tempParentEquip:any;
  tempChildEquip:any;
  equipDeleted: boolean = false;
  checkEquipConfigIsSensorOrBuildingOrHeatExchanger = false;

  ngOnInit(): void {
    this.isLoading = true;
    this.configId = "";
    let equipId = this.data.equipId;
    if (this.data?.childData?.class.includes('btuMeter') || this.data?.childData?.class.includes('pump1Vertical')) {
      equipId = this.data?.childData?.equipData?.subEquips[0]?.equipId;
    }
    setTimeout(() => {
      this.apiService.getEquipConfigs({ "plantId": this.data.plantId, "equipId": equipId, status: 'DRAFT' }).subscribe((response) => {
        if (response && response.configId) {
          this.configId = response.configId;
          if (response) {
            this.getPointListData(response);
          } else {
            this.getPointListData({});
          }
        } else {
          this.getPointListData({});
        }
      });
    }, 300);
    this.checkEquipConfig();
  }

  equipPrimaryPoint(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.equipList.parentEquip.points, event.previousIndex, event.currentIndex);
    this.isReordered = true;
  }

  dropSubEquip(event: CdkDragDrop<string[]>, subEquipData) {
    moveItemInArray(subEquipData.points, event.previousIndex, event.currentIndex);
    this.equipList.childEquip.points = subEquipData.points;
    this.isReordered = true;
  }

  // Check if the equip is sensor node or building or heat exchanger
  checkEquipConfig() {
    this.isSensorNode = this.data.isSensorNode;
    const equipsToIgnore = ['building2Pipe', 'building2PipeHot', 'dualTempBuilding2Pipe', 'heatExchanger_backward', 'heatExchanger_forward', "building4Pipe"];
    this.checkEquipConfigIsSensorOrBuildingOrHeatExchanger = this.isSensorNode;
    if (!this.checkEquipConfigIsSensorOrBuildingOrHeatExchanger) {
      this.checkEquipConfigIsSensorOrBuildingOrHeatExchanger = equipsToIgnore.includes(this.data.class);
    }
  }

  addPointModel() {
    const dialogRef = this.dialog.open(AddPointModelComponent, {
      panelClass: 'cpmAddPoint-modal-container',
      width: '80%',
      data: {
        sites: this.data.building || [],
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.length > 0) {
          result.forEach((customPoint) => {
            if (!this.customPointList.some(pointData => pointData.id === customPoint.id)) {
              customPoint.master = false;
              customPoint.click = false;
              customPoint.hover = false;
              customPoint.isValueSelectable = false;
              this.customPointList.push(customPoint);
            }
          });
          this.saveButtonDisable = false;
        }
      }
    });
  }

  addAlertModel() {
    const dialogRef = this.dialog.open(AddAlertModelComponent, {
      panelClass: 'addAlert-modal-container',
      width: '40%',
      data: { alerts:this.alertList, "siteId" : this.data.siteId}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.length > 0) {
          result.forEach((alertData) => {
            if (!this.alertList.some(alert => alert.alertId === alertData.alertId)) {
              this.alertList.unshift(alertData);
            }
          })
        }
      }
    });
  }

  deleteAlertClicked(alertData) {
    const dialogRef = this.dialog.open(ConfirmationModelComponent, {
      width: '450px',
      disableClose: true
    });
    // tslint:disable-next-line
    const htmlContent = `<div>Are you sure you want to delete the Alert: ${alertData.alertName} <div>`;
    dialogRef.componentInstance.title = 'Confirmation';
    dialogRef.componentInstance.htmlContent = this.dom.sanitize(SecurityContext.HTML, htmlContent);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result == 'confirm') {
        this.alertList.splice(this.alertList.findIndex(alert => alert.alertId === alertData.alertId), 1);
      }
    });
  }

  deleteEquip() {
    const dialogRef = this.dialog.open(ConfirmationModelComponent, {
      width: '450px',
      disableClose: true
    });
    // tslint:disable-next-line
    const htmlContent = `<div>Are you sure you want to delete the Equip: ${this.data?.childData?.equipData?.displayName} <div>`;
    dialogRef.componentInstance.title = 'Confirmation';
    dialogRef.componentInstance.htmlContent = this.dom.sanitize(SecurityContext.HTML, htmlContent);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result == 'confirm') {
        let dialogReturnData = {
          action: 'delete',
          equipData: this.data
        }
        this.dialogRef.close(dialogReturnData);
      }
    });
  }

  deleteCustomPoint(custompoint) {
    const dialogRef = this.dialog.open(ConfirmationModelComponent, {
      width: '450px',
      disableClose: true
    });
    // tslint:disable-next-line
    const htmlContent = `<div>Are you sure you want to delete the Custom Point: ${custompoint.pointName} <div>`;
    dialogRef.componentInstance.title = 'Confirmation';
    dialogRef.componentInstance.htmlContent = this.dom.sanitize(SecurityContext.HTML, htmlContent);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result == 'confirm') {
        this.customPointList.splice(this.customPointList.findIndex(point => point.pointId === custompoint.pointId), 1);
      }
    });
  }

  cancel() {
    let dialogReturnData = {
      action:'close'
    }
    this.dialogRef.close(dialogReturnData);
  }

  setCustomPointType(key) {
    let pointType = '';
    if (key.hasOwnProperty('his')) {
      pointType = 'HIS';
    } else if(pointType != 'HIS' && key.hasOwnProperty('writable')) {
      pointType = 'WRITABLE';
    }
    return pointType == '' ? 'READABLE': pointType;
  }

  setPointType(tags) {
    let pointType = '';
    if (tags.includes('his')) {
      pointType = 'HIS';
    } else if(pointType != 'HIS' && tags.includes('writable')) {
      pointType = 'WRITABLE';
    }
    return pointType == '' ? 'READABLE': pointType;
  }

  saveConfig() {
    if (!this.isReordered) {
      if (this.isSensorNode && !(this.data.childData.class.includes('btuMeter'))) {
        let changedPoints = this.equipList.parentEquip.points.filter(childObj => this.tempParentEquip.points.find(tempChildObj => (childObj.pointId === tempChildObj.pointId && childObj.master != tempChildObj.master)));
        if(changedPoints.length){
          this.isReordered = true;
        }
      } else {
        this.tempChildEquip?.forEach(tempChildData => {
          this.equipList.childEquip.forEach(childData => {
            if (tempChildData.equipId == childData.equipId) {
              let changedPoints = childData.points.filter(childObj => tempChildData.points.find(tempChildObj => (childObj.pointId === tempChildObj.pointId && childObj.master != tempChildObj.master)));
              if(changedPoints.length){
                this.isReordered = true;
              }
            }
          });
        });
      }
    }
  
    let requestData = {
      "equipId": this.equipList.parentEquip.equipId,
      "plantId": this.data.plantId,
      "equipMetaData": {},
      "tags": [],
      "alertConfigDtos": [],
      "pointList": [],
      "statusType": "DRAFT"
    }
    requestData.pointList = [];
    // parent equips pointList
    requestData.tags = this.data.childData.tags;
    this.equipList?.parentEquip?.points?.forEach(point => {
      if (point.master || point.click || point.hover) {
        point.pointMetaData = {
          "custom": false
        }
        point['pointType'] = this.setPointType(point.tags);
        requestData.pointList.push(point);
      }
    });
    // Child equips pointList
    this.equipList.childEquip.forEach(equip => {
      equip.points.forEach(point => {
        if (point.master || point.click || point.hover) {
          point.pointMetaData = {
            "custom": false
          }
          point['pointType'] = this.setPointType(point.tags);
          requestData.pointList.push(point);
        }
      });


    })
    this.customPointList.forEach(point => {
      let tempObj = {};
      const displayName = point?.shortDis ? point?.shortDis : point?.pointName;
      tempObj["pointId"] = point.pointId ? point.pointId : point.id;
      tempObj["pointName"] = point.shortName ? point.shortName : displayName;
      tempObj["equipRef"] = point.equipRef;
      tempObj["click"] = point.click;
      tempObj["hover"] = point.hover;
      tempObj["master"] = point.master;
      tempObj["tags"] = point.tags;
      if (!point.pointType) {
        tempObj['pointType'] = this.setCustomPointType(point);
      } else {
        tempObj['pointType'] = point.pointType;
      }
      tempObj['pointMetaData'] = {
        "custom": true
      }
      requestData.pointList.push(tempObj);
    });
    // alert list request
    requestData["alertConfigDtos"] = [];
    this.alertList.forEach(alert => requestData["alertConfigDtos"].push(alert));

    requestData["configId"] = this.configId;
    requestData["pipeRefs"] = this.pipeRefs;
    requestData.equipMetaData = {};
    let subEquipObj;
    if (this.isHeaderPipe(this.data.childData.class) || this.isBypassPipe(this.data.childData.class)) {

      this.data?.childData?.equipData.subEquips[0].equips.forEach((subEquipData) => {
        subEquipObj = this.equipList.parentEquip.points.filter(equipObj => {
          return equipObj.equipId === subEquipData.equipId;
        })

        this.data.childData.equipData.subEquips[0].equips = this.data?.childData?.equipData.subEquips[0].equips.sort((a, b) => {
          const posA = subEquipObj.findIndex(point => point.pointId === a.pointId);
          const posB = subEquipObj.findIndex(point => point.pointId === b.pointId);
          if (posA < posB) {
            return -1;
          } else if (posA > posB) {
            return 1;
          } else {
            return 0;
          }
        });

        this.data.childData.equipData.subEquips[0].equips.forEach(sensorObj => {
          subEquipObj.forEach(subEquip => {
            if (sensorObj.pointId == subEquip.pointId) {
              sensorObj.master = subEquip.master;
              sensorObj.click = subEquip.click;
              sensorObj.hover = subEquip.hover;
            }
          });
        });
      });

    } else {
      this.data?.childData?.equipData?.subEquips?.forEach((subEquipData, index) => {
        subEquipObj = this.equipList.childEquip.filter(equipObj => {
          return equipObj.equipId === subEquipData.equipId;
        })

        if (subEquipObj && subEquipObj?.length) {
          this.data.childData.equipData.subEquips[index].equips = this.data.childData.equipData.subEquips[index].equips.sort((a, b) => {
            const posA = subEquipObj[0].points.findIndex(point => point.pointId === a.pointId);
            const posB = subEquipObj[0].points.findIndex(point => point.pointId === b.pointId);
            if (posA < posB) {
              return -1;
            } else if (posA > posB) {
              return 1;
            } else {
              return 0;
            }
          });

          subEquipData.equips.forEach(sensorObj => {
            subEquipObj[0].points.forEach(subEquip => {
              if (sensorObj.pointId == subEquip.pointId) {
                sensorObj.master = subEquip.master;
                sensorObj.click = subEquip.click;
                sensorObj.hover = subEquip.hover;
              }
            });
          });
        }
      });
    }

    this.dialogRef.close({ "payload": requestData, "action": "save", "equipList": this.equipList, "reorderedSequence": this.data.childData, "isReordered": this.isReordered  });
  }

  checkMasterDisable(pointData) {
    this.masterCount = 0;
    if(!this.isSensorNode || this.data.childData.class.includes('btuMeter')){
      this.equipList?.parentEquip?.points?.forEach((point) => {
        if (point.master) {
          this.masterCount++;
        }
      })
    }

    this.equipList.childEquip.forEach( equip =>{
      equip.points.forEach( point => {
        if (point.master) {
          this.masterCount++;
        }
      });
    })

    this.customPointList.forEach((point) => {
      if (point.master) {
        this.masterCount++;
      }
    })

    if (this.masterCount >= this.pointLimit && !pointData.master) {
      return true;
    } else {
      return false;
    }
  }

  
  checkChildMasterDisable(pointData) {
    if(this.data.isEquipConnected){
      return true
    }
    this.masterCount = 0;
    if(!this.isSensorNode || this.data.childData.class.includes('btuMeter')){
      this.equipList.parentEquip.points.forEach((point) => {
        if (point.master) {
          this.masterCount++;
        }
      })
    }

    this.equipList.childEquip.forEach( equip =>{
      equip.points.forEach( point => {
        if (point.master) {
          this.masterCount++;
        }
      });
    })

    this.customPointList.forEach((point) => {
      if (point.master) {
        this.masterCount++;
      }
    })

    if (this.masterCount >= this.pointLimit && !pointData.master) {
      return true;
    } else {
      return false;
    }
  }

  getPointListData(existingRes) {
    let isConfigAdded: boolean;
    if (Object.keys(existingRes).length === 0
      && existingRes.constructor === Object) {
      isConfigAdded = false;
    } else {
      isConfigAdded = true;
    }

    this.equipList.parentEquip = {
      equipId: this.data.childData.equipId,
      displayName: this.data.childData.displayName,
      name: this.data.childData.name,
      class: this.data.childData.class,
      tags: this.data.childData.tags,
      pointLimit: this.data.childData.pointLimit,
      points: []
    }
    this.pipeRefs = [];


    if (this.data.childData.class.includes('btuMeter') || this.data.childData.class.includes('pump1Vertical')) {
      this.equipList.parentEquip.equipId = this.data.childData.equipData?.subEquips[0]?.equipId;
    }
    this.checkEquipConfig();
    let equipIds = [this.equipList.parentEquip.equipId];
    this.data.childData.equipData.subEquips.forEach(subEquip => {
      subEquip.equips.forEach(equip => {
        // if (equip?.subEquipType && equip?.subEquipType != "btuMeter" && !equip.subEquipType.includes('pump1Vertical')) {
        //   this.pointLimit += equip.pointLimit;
        // }
      });
      if (!equipIds.includes(subEquip.equipId)) {
        equipIds.push(subEquip.equipId);
      }
    });
    this.apiCallTogetEquipsAndSubEquipsPoints(equipIds, existingRes);
  }

  apiCallTogetEquipsAndSubEquipsPoints(equipIds, existingRes) {
    this.apiService.getPointsForEquipsSubequips(equipIds).subscribe((response: any) => {
      if (!response?.length) {
        this.equipDeleted = true;
        this.isLoading = false;
        this.dialogRef.updatePosition({})
      } else {
        let equipIndex = response.findIndex((e) => e.equipId == this.equipList.parentEquip.equipId);
        if (equipIndex > -1 && response[equipIndex]?.pointList) {
          let pointsData = response[equipIndex]?.pointList?.map(point => {
            point['master'] = false;
            point['click'] = false;
            point['hover'] = false;
            point['custom'] = false;
            return point;
          });

          this.equipList.parentEquip.points = pointsData;

          if (this.isHeaderPipe(this.data.childData.class) || this.isBypassPipe(this.data.childData.class)) {
              pointsData = pointsData.sort((a, b) => {
                const posA = this.data.childData.equipData.subEquips[0].equips.findIndex(point => point.pointId === a.pointId);
                const posB =  this.data.childData.equipData.subEquips[0].equips.findIndex(point => point.pointId === b.pointId);
                if (posA < posB) {
                  return -1;
                } else if (posA > posB) {
                  return 1;
                } else {
                  return 0;
                }
              });

            // });
            let childData = {
              equipId: this.data.childData.equipId,
              displayName: this.data.childData.displayName,
              name: this.data.childData.name,
              points: pointsData
            }
            this.equipList.childEquip.push(childData);
          }
        }

        if (!this.isHeaderPipe(this.data.childData.class) && !this.isBypassPipe(this.data.childData.class) && !this.data.childData.class.includes('pump1Vertical') && !this.data.childData.class.includes('btuMeter')) {
          this.data.childData.equipData.subEquips.forEach((subEquip) => {
            let equipIndex = response.findIndex((e) => e.equipId == subEquip.equipId);
            if (equipIndex > -1) {
              let pointList = [];
              if (response[equipIndex].pointList) {
                pointList = response[equipIndex].pointList.map(point => {
                  point['master'] = false; //setting default child sensor selected for non config equip
                  point['click'] = false;
                  point['hover'] = false;
                  point['custom'] = false;
                  return point;
                });
              }

              let subEquipObj;
              subEquip.equips.forEach((subEquipData, index) => {
                if(subEquipData.equip[0] != "btuMeter"){
                  subEquipObj = subEquip.equips.filter(equipObj => {
                    return equipObj.pointId === subEquipData.pointId;
                  })
                }

                pointList = pointList.sort((a, b) => {
                  const posA = subEquipObj[0].pointId.indexOf(a.pointId);
                  const posB = subEquipObj[0].pointId.indexOf(b.pointId);
                  if (posA < posB) {
                    return -1;
                  } else if (posA > posB) {
                    return 1;
                  } else {
                    return 0;
                  }
                });
              });

              let childData = {
                equipId: subEquip.equipId,
                displayName: subEquip.subEquipsName,
                name: subEquip.subEquipsName,
                points: pointList
              }
              this.equipList.childEquip.push(childData);
            };
          });
        }
        if(Object.keys(existingRes).length){
          let nonCustomPoints = existingRes.pointList ? existingRes.pointList.filter((point => !point.pointMetaData?.custom)):[]
          this.customPointList = existingRes.pointList ? existingRes.pointList.filter( point => point.pointMetaData?.custom): [];
          this.alertList = existingRes.alertConfigDtos ? existingRes.alertConfigDtos : [];

          //Map selection values to parent equip
          this.equipList.parentEquip.points =  this.equipList.parentEquip.points.map((point)=>{
            let pointObj = nonCustomPoints.find((resPoint)=>point.pointId == resPoint.pointId);
            if(pointObj) {
              Object.keys(pointObj).forEach((key)=>{
                if(point.hasOwnProperty(key)) {
                  point[key] = pointObj[key];
                }
                
              })
            }
            return point;
          });

          //Map selection values to chilp equip
          this.equipList.childEquip = this.equipList.childEquip.map((childEquip)=>{
            childEquip.points =  childEquip.points.map((point)=>{
              let pointObj = nonCustomPoints.find((resPoint)=>point.pointId == resPoint.pointId);
              if(pointObj) {
                Object.keys(pointObj).forEach((key)=>{
                  if(point.hasOwnProperty(key)) {
                    point[key] = pointObj[key];
                  }
                  
                })
              }
              return point;
            });
            return childEquip;
          })
          if(existingRes.pipeRefs){
            this.pipeRefs = existingRes.pipeRefs;
          }
        }
        this.isLoading = false;
        this.saveButtonDisable = false;
        this.tempParentEquip = this.equipList.parentEquip;
        this.tempParentEquip = JSON.parse(JSON.stringify(this.equipList.parentEquip));
        this.tempChildEquip =  JSON.parse(JSON.stringify( this.equipList.childEquip));
      }
    }, 
    err => {
      this.isLoading = false;
      this.equipList.parentEquip.points = [];
      this.equipList.childEquip = this.equipList.childEquip.map((childEquip)=>{
        childEquip.points = [];
      });
      this.saveButtonDisable = true;
    });
  }

  isHeaderPipe(equipName) {
    return this.data.equipConfiguration?.headerPipeList?.indexOf(equipName) != -1;
  }

  checkClickDisable(point){
    if(this.data.equipConfiguration.buildingList?.indexOf(this.data.class) != -1){
      return false;
    }else{
      let isDefaultPoint = false;
      let equipName = JSON.parse(JSON.stringify(this.data.class));
      if(equipName.includes('pump')){
        equipName = 'pump1';
      }
      defaultClickViewPoint[equipName].forEach(pointTags => {
        if (pointTags.every(value =>point.tags.includes(value))) {
          isDefaultPoint = true;
          }
      });
      return isDefaultPoint;
    }
  }

  isBypassPipe(equipName) {
    return this.data.equipConfiguration.bypassPipeList.indexOf(equipName) != -1;
  }

  addTooltip(equipName) {
    if (equipName.length > 50) {
      return  this.checkEquipConfigIsSensorOrBuildingOrHeatExchanger ? equipName.substring(0, 50) + '...' : equipName.substring(0, 70);
    } 
      return equipName; 
  }

  // Display note in config modal for all equips except btuMeter and pump1Vertical
  get showNote(): boolean {
    return this.data.isEquipConnected &&
      !this.equipDeleted &&
      !(this.data.childData.class.includes('btuMeter') || this.data.childData.class.includes('pump1Vertical'));
  }
}
